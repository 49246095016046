import Utility from '~/services/Utility';
import { type UserSurveyDTO } from '~/services/payment';

interface State {
  surveyPopupVisible: boolean;
  surveyPopupData: UserSurveyDTO | null;
  surveyStudySeq: number | null;
  surveyMode: 'add' | 'update' | 'read';
}

export const useSurveyStore = defineStore('user-survey', {
  state: (): State => ({
    surveyPopupVisible: false,
    surveyPopupData: null,
    surveyStudySeq: null,
  }),
  actions: {
    setSurveyPopupData(data: UserSurveyDTO) {
      this.surveyPopupData = data;
    },
    setSurveyPopupVisible(flag: boolean) {
      this.surveyPopupVisible = flag;
    },
    setSurveyStudySeq(studySeq: number | null) {
      this.surveyStudySeq = studySeq;
    },
    setSurveyMode(mode: 'add' | 'update' | 'read') {
      this.surveyMode = mode;
    },
  },
  getters: {
    getSurveyPopupData(state): UserSurveyDTO | null {
      return state.surveyPopupData;
    },
  },
});
